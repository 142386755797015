export enum GITHUB_TYPES {
  GET_USERS_START = "GET_USERS_START",
  GET_USERS_SUCCESS = "GET_USERS_SUCCESS",
  GET_USERS_FAILD = "GET_USERS_FAILD",
  GET_USER_START = "GET_USER_START",
  GET_USER_SUCCESS = "GET_USER_SUCCESS",
  GET_USER_FAILED = "GET_USER_FAILED",
  GET_USER_REPOS_START = "GET_USER_REPOS_START",
  GET_USER_REPOS_SUCCESS = "GET_USER_REPOS_SUCCESS",
  GET_USER_REPOS_FAILD = "GET_USER_REPOS_FAILD",
  SET_LOADING = "SET_LOADING",
  CLEAR_USERS = "CLEAR_USERS",
  SET_ALERT = "SET_ALERT",
}
